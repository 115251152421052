import { ApplicationRef, Inject, Injectable, inject, signal } from '@angular/core';
import html2canvas from 'html2canvas';
import { distinctUntilChanged, filter } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FeatureFlagService } from '../../modules/feature-flag/feature-flag.service';
import { FeatureFlagConfigKeys } from '../../modules/feature-flag/feature-flag.type';
import { PixelStreamingService } from '../../services/pixelstreaming.service';
import { WINDOW } from '../../tokens/window.token';
import { IntroService } from '../intro/intro.service';

/**
 * Сервис для создания снимков экрана
 * @class
 */
@Injectable({ providedIn: 'root' })
export class SnapshotService {
  /**
   * Сигнал для снимка экрана
   */
  snapshot = signal('');

  /**
   * Внедряет зависимость IntroService в сервис introService.
   *
   * @param {IntroService} IntroService - Сервис IntroService для внедрения зависимости.
   */
  #introService = inject(IntroService);

  /**
   * Поток для переподключения к WebRTC
   */
  #webRtcReconnection$ = this.pixelStreamingService.webRtcReconnection$;

  /**
   * Конструктор SnapshotService
   * @constructor
   * @param {Window} window - Объект window.
   * @param {ApplicationRef} applicationRef - Ссылка на приложение
   * @param {PixelStreamingService} pixelStreamingService - Сервис для потокового воспроизведения пикселей
   * @param {FeatureFlagService} featureFlagService - Сервис для управления функциональными флагами.
   *
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private applicationRef: ApplicationRef,
    private pixelStreamingService: PixelStreamingService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.#webRtcReconnection$
      .pipe(
        distinctUntilChanged(),
        filter(() => !this.#introService.isIntroScreenVisible),
      )
      .subscribe((value) => this.webRtcReconnectionForMakeSnapshot(value));
  }

  /**
   * Определяет, является ли текущая страница корневой страницей.
   *
   * @returns {boolean} Верно, если текущая страница является корневой страницей, иначе неверно.
   */
  get showIntoPage(): boolean {
    if (!environment.hasIntoPage) {
      return false;
    }

    let pathname = this.window.location.pathname;

    if (pathname && (pathname.match(/\//g) ?? []).length > 1) {
      pathname = pathname.match(/\/.*\//)?.[0]?.slice(0, -1) ?? '';
    }

    if (pathname !== '/') {
      return !this.featureFlagService.isFeatureOn(pathname.replace('/', '').toUpperCase() as FeatureFlagConfigKeys);
    }

    if (pathname === '/' && (this.window.location.search.includes('coords=') || this.window.location.search.includes('hideInterface'))) {
      return false;
    }

    return pathname === '/' && !this.window.location.href.includes('?data=');
  }

  /**
   * Отслеживание снимка экрана
   * @method
   * @param {boolean} value - Значение для отслеживания
   * @returns {Promise<void>}
   */
  async webRtcReconnectionForMakeSnapshot(value: boolean): Promise<void> {
    if (value) {
      const snapshot: HTMLElement = (this.applicationRef.components[0].instance).getElementRef().nativeElement;
      this.snapshot.set(await this.takeSnapshot(snapshot));
    } else {
      this.snapshot.set('');
    }
  }

  /**
   * Захватывает снимок состояния приложения в виде объекта Blob.
   *
   * Этот метод делает снимок конкретного элемента видео в приложении
   * и преобразует его в объект Blob, который можно использовать для различных целей,
   * таких как сохранение снимка или отправка его по сети.
   *
   * @return {Promise<Blob | null>} Промис, который разрешается объектом Blob, представляющим снимок,
   *                                или null, если видео элемент не найден.
   */
  async getAppSnapshotAsBlob(): Promise<Blob | null> {
    const snapshot: HTMLElement = (this.applicationRef.components[0].instance).getElementRef().nativeElement;
    const videoElement: HTMLElement | null = snapshot.querySelector('#streamingVideo');
    if (!videoElement) {
      return null;
    }
    const canvas = await html2canvas(videoElement, { scale: 1 });
    return await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
  }

  /**
   * Создает снимок указанного элемента HTML.
   * @param {HTMLElement} htmlElement - Элемент HTML, для которого необходимо создать снимок.
   * @param {number} [scale=1] - Масштаб, с которым будет создан снимок (по умолчанию 1).
   * @returns {Promise<string>} - Обещание с данными изображения в формате webp.
   */
  private async takeSnapshot(htmlElement: HTMLElement, scale = 1): Promise<string> {
    const canvas = await html2canvas(htmlElement, { scale });
    return canvas.toDataURL('image/webp');
  }
}
